<script setup>
import { usePage } from '@inertiajs/vue3';
import { defineAsyncComponent } from 'vue';

import Header from '@/Layouts/Partials/App/Header.vue';
import { useSetLocale } from '@/Plugins/i18n.js';
import ContactModal from '@/Components/ContactModal.vue';

const Footer = defineAsyncComponent(() => import(`./Partials/App/Footer.vue`));
useSetLocale(usePage().props?.locale || import.meta.env.VITE_APP_LOCALE || 'en');
</script>

<template>
  <Header />
  <main>
    <slot />
  </main>
  <Footer />
  <ContactModal />
</template>

<style scoped></style>

<script setup></script>

<template>
  <button
    class="inline-flex items-center justify-center rounded-full bg-yellow px-6 py-2.5 text-center text-lg font-bold text-[#191817] transition hover:bg-yellow-600 focus:outline-none focus:ring-4 focus:ring-yellow-400 dark:bg-yellow dark:hover:bg-yellow-600 dark:focus:ring-yellow-700"
  >
    <slot></slot>
  </button>
</template>

<style scoped></style>

<script setup>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

const { texts } = defineProps({
  texts: Array,
});

const formatText = (text) => {
  return text.trim().split(' ')[text.trim().split(' ').length - 1];
};

const getFirstWord = () => {
  return texts[0].trim().split(' ')[0];
};

const i18n = useI18n();

const fontSize = ref({
  en: {
    '--lg-infinit-size': '55px',
    '--lg-infinit-h': '64px',
    '--sm-infinit-size': '36px',
  },
  fr: {
    '--lg-infinit-size': '48px',
    '--sm-infinit-size': '34px',
  },
  nl: {
    '--lg-infinit-size': '42px',
    '--sm-infinit-size': '24px',
  },
});
</script>

<template>
  <div
    class="content"
    :style="fontSize[i18n.locale.value]"
    :data-locale="i18n.locale.value"
  >
    <div class="content__container">
      <p class="content__container__text">
        {{ getFirstWord() }}
      </p>

      <ul class="content__container__list">
        <template
          v-for="(text, index) in texts"
          :key="`loop_${index}`"
        >
          <li class="content__container__list__item">{{ formatText(text) }}</li>
        </template>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --lg-infinit-size: 50px;
}
.content {
  overflow: hidden;
  text-align: left;

  &__container {
    font-weight: 600;
    line-height: 1.15;
    height: var(--sm-infinit-size);
    font-size: var(--sm-infinit-size);
    display: inline-flex;
    align-items: baseline;
    justify-content: flex-start;
    color: white;
    @media screen and (min-width: 576px) {
      height: var(--lg-infinit-size);
      font-size: var(--lg-infinit-size);
    }

    &:before {
      content: '< ';
      padding-right: 20px;
    }

    &:after {
      content: ' />';
      padding-left: 20px;
    }

    &:after,
    &:before {
      color: white;
      animation-name: opacity;
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }

    &__text {
      margin: 0;
    }

    &__list {
      margin-top: 0;
      padding-left: 10px;
      text-align: left;
      list-style: none;
      //height: 260px;

      animation-name: change;
      animation-duration: 13s;
      animation-iteration-count: infinite;

      &__item {
        //padding-bottom: 15px;
        margin: 0;
      }
    }
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}
</style>

import mitt from 'mitt';
import { inject } from 'vue';

const emitter = Symbol('emitter');

export const useEmitter = () => inject(emitter);

export const createMitt = {
  install: (app) => {
    app.provide(emitter, mitt());
  },
};

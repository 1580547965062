<template>
  <svg
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1480_22440)">
      <path
        d="M0 0.5H24V16.5H0V0.5Z"
        fill="white"
      />
      <path
        d="M0 0.5H24V5.83333H0V0.5Z"
        fill="#CD1F2A"
      />
      <path
        d="M0 11.0967H24V16.43H0V11.0967Z"
        fill="#1D4185"
      />
    </g>
    <defs>
      <clipPath id="clip0_1480_22440">
        <rect
          y="0.5"
          width="24"
          height="16"
          rx="1.6"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

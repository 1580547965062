<script setup>
import FormElement from '@/Components/FormElements/FormElement.vue';
import { ref, useSlots } from 'vue';

const value = defineModel('modelValue', { default: null, type: [String, Number] });
const slots = useSlots();
const el = ref(null);

defineProps({
  label: [String, null],
  type: {
    default: 'text',
    type: String,
  },
  error: [String, null],
  disabled: Boolean,
  required: Boolean,
});
</script>

<template>
  <FormElement ref="el">
    <template
      v-if="label"
      #label
    >
      {{ label }}
    </template>
    <template
      v-if="error"
      #error
    >
      {{ error }}
    </template>

    <template
      v-if="slots.icon"
      #icon
    >
      <slot name="icon" />
    </template>
    <input
      :id="el?.id"
      v-model="value"
      :type="type"
      :disabled="disabled"
      class="block w-full rounded-xl border border-gray-300 px-5 py-3.5 leading-none text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
      :class="{
        'ps-10': slots.icon,
        'bg-white': !error,
        'bg-red-200': error,
      }"
    />
  </FormElement>
</template>

<style scoped></style>

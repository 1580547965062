<template>
  <svg
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1480_22420)">
      <path
        d="M0 0.5H24V16.5002H0V0.5Z"
        fill="white"
      />
      <path
        d="M13.5 0.499813H10.5V6.99979H0V9.99979H10.5V16.4998H13.5V9.99979H24V6.99979H13.5V0.499813Z"
        fill="#D80027"
      />
      <path
        d="M18.4586 11.2822L24 14.3608V11.2822H18.4586ZM14.6086 11.2822L24 16.4996V15.0243L17.2643 11.2822H14.6086ZM21.4984 16.4996L14.6086 12.6716V16.4996H21.4984Z"
        fill="#0052B4"
      />
      <path
        d="M14.6086 11.2824L24 16.4998V15.0245L17.2643 11.2824H14.6086Z"
        fill="white"
      />
      <path
        d="M14.6086 11.2824L24 16.4998V15.0245L17.2643 11.2824H14.6086Z"
        fill="#D80027"
      />
      <path
        d="M4.23473 11.2828L0 13.6354V11.2828H4.23473ZM9.39131 11.9462V16.5002H1.19489L9.39131 11.9462Z"
        fill="#0052B4"
      />
      <path
        d="M6.73561 11.2824L0 15.0245V16.4998L9.39131 11.2824H6.73561Z"
        fill="#D80027"
      />
      <path
        d="M5.54133 5.71719L0 2.63863V5.71719H5.54133ZM9.39131 5.71719L0 0.499813V1.97516L6.73561 5.71719H9.39131ZM2.50153 0.499813L9.39131 4.32782V0.499813H2.50153Z"
        fill="#0052B4"
      />
      <path
        d="M9.39131 5.71719L0 0.499813V1.97516L6.73561 5.71719H9.39131Z"
        fill="white"
      />
      <path
        d="M9.39131 5.71719L0 0.499813V1.97516L6.73561 5.71719H9.39131Z"
        fill="#D80027"
      />
      <path
        d="M19.7652 5.71733L24 3.36467V5.71733H19.7652ZM14.6086 5.05386V0.499905H22.8051L14.6086 5.05386Z"
        fill="#0052B4"
      />
      <path
        d="M17.2643 5.71724L24 1.9752V0.499859L14.6086 5.71724H17.2643Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1480_22420">
        <rect
          y="0.5"
          width="24"
          height="16"
          rx="2.13333"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

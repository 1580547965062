<script setup>
import { Link } from '@inertiajs/vue3';
import LanguageSwitcher from '@/Components/LanguageSwitcher.vue';
import { useWindowScroll, useWindowSize } from '@vueuse/core';
import { ref, watch } from 'vue';
import CtaButton from '@/Components/CtaButton.vue';
import BurgerButton from '@/Components/BurgerButton.vue';

const header = ref(null),
  isMobileMenuOpen = ref(false),
  { width } = useWindowSize(),
  { y } = useWindowScroll();

watch(width, (current, previous) => {
  if (current >= 768 && previous < 768) {
    if (isMobileMenuOpen.value) {
      closeMobileMenu();
    }
  }
});

// const scrollToContainer = (containerToScroll) => {
//   if (isMobileMenuOpen.value) {
//     closeMobileMenu();
//   }
//
//   window.scrollTo({
//     top: document.querySelector('[data-anchor=' + containerToScroll + ']')?.offsetTop,
//     behavior: 'smooth',
//   });
// };

const closeMobileMenu = () => {
  document.body.classList.remove('overflow-hidden');
  isMobileMenuOpen.value = false;
};

const onMenuToggle = () => {
  if (isMobileMenuOpen.value) {
    closeMobileMenu();
    return;
  }

  document.body.classList.add('overflow-hidden');
  isMobileMenuOpen.value = true;
};
</script>

<template>
  <header
    ref="header"
    class="sticky top-0 z-10 border-b transition"
    :class="{
      'border-b-gray bg-white': y > 100,
      'border-b-transparent': y < 100 && y !== 0,
      'border-b-gray lg:border-b-transparent': y === 0,
      'bg-custom': isMobileMenuOpen,
    }"
  >
    <div class="container flex items-center justify-between py-3 transition lg:py-5">
      <div class="flex items-center">
        <svg
          class="mr-10 h-12 md:h-8"
          viewBox="0 0 67 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.1546 11.4017H18.7429V6.45378H13.795C8.30924 6.45378 5.56639 8.09412 5.56639 11.4017C5.56639 13.6067 6.93782 15.2471 9.68067 16.3496C12.4235 17.4521 13.2571 17.9899 12.1546 17.9899H4.9479V6.45378H0V18.9042L3.73782 22.9378H10.4874C15.9731 22.9378 18.716 21.2975 18.716 17.9899C18.716 15.7849 17.3445 14.1445 14.6017 13.042C11.8857 11.9395 11.0521 11.4017 12.1546 11.4017Z"
            fill="#FFB20D"
          />
          <path
            d="M0.0268555 4.51765L4.97475 0H0.0268555V4.51765Z"
            fill="black"
          />
          <path
            d="M32.6183 19.7916H32.4032C31.704 21.5395 30.6015 22.6958 28.02 22.6958C25.6536 22.6958 23.9595 21.4051 23.9595 19.1194V18.4202C23.9595 16.242 25.6267 14.7631 28.7729 14.7631H32.4301V13.9025C32.4301 12.5311 31.8654 11.9664 30.3864 11.9664C28.9074 11.9664 28.3696 12.5042 28.2889 13.3916L28.262 13.6874H24.4435C24.5511 10.6219 26.4334 9.03532 30.5746 9.03532C34.7158 9.03532 36.4368 10.4067 36.4368 14.0639V18.4471C36.4368 19.0656 36.6788 19.3076 37.2973 19.3076H37.7275V22.3462C37.1628 22.4538 36.5175 22.5614 35.5763 22.5614C33.667 22.5345 32.6183 21.7546 32.6183 19.7916ZM29.3645 19.7647C31.6502 19.7647 32.4301 19.1462 32.4301 17.2908V16.995H29.3376C28.3964 16.995 27.8586 17.4521 27.8586 18.2589V18.5278C27.8586 19.3345 28.3964 19.7647 29.3645 19.7647Z"
            fill="black"
          />
          <path
            d="M52.3292 15.516V16.1883C52.3292 20.1143 50.6082 22.6421 47.3275 22.6421C44.9074 22.6421 43.6435 21.4589 43.0519 19.3614H42.8368V26.5143H38.8569V9.38493H42.8368V12.3429H43.0519C43.6435 10.2185 44.9074 9.06224 47.3275 9.06224C50.5813 9.08913 52.3292 11.6169 52.3292 15.516ZM45.5527 19.2538C47.3813 19.2538 48.2149 18.5009 48.2149 16.4572V15.2471C48.2149 13.2303 47.3813 12.4774 45.5527 12.4774C43.7242 12.4774 42.8368 13.2303 42.8368 15.1395V16.4841C42.8368 18.5009 43.6704 19.2538 45.5527 19.2538Z"
            fill="black"
          />
          <path
            d="M66.8502 15.516V16.1883C66.8502 20.1143 65.1292 22.6421 61.8485 22.6421C59.4283 22.6421 58.1645 21.4589 57.5729 19.3614H57.3578V26.5143H53.3779V9.38493H57.3578V12.3429H57.5729C58.1645 10.2185 59.4283 9.06224 61.8485 9.06224C65.1023 9.08913 66.8502 11.6169 66.8502 15.516ZM60.0737 19.2538C61.9023 19.2538 62.7359 18.5009 62.7359 16.4572V15.2471C62.7359 13.2303 61.9023 12.4774 60.0737 12.4774C58.2452 12.4774 57.3578 13.2303 57.3578 15.1395V16.4841C57.3578 18.5009 58.1914 19.2538 60.0737 19.2538Z"
            fill="black"
          />
        </svg>

        <div
          class="bottom-0 left-0 right-0 top-[73px] items-center bg-custom max-lg:fixed max-lg:flex-col max-lg:gap-6 max-lg:px-5 max-lg:pb-10 max-lg:pt-24 lg:flex lg:bg-transparent"
          :class="{
            hidden: !isMobileMenuOpen,
            flex: isMobileMenuOpen,
          }"
        >
          <Link
            href="/"
            class="header-link"
            @click.prevent.stop="closeMobileMenu"
          >
            {{ $t('Home') }}
          </Link>
          <Link
            href="/"
            class="header-link"
            @click.prevent.stop="closeMobileMenu"
          >
            {{ $t('Services') }}
          </Link>
          <Link
            href="/"
            class="header-link"
            @click.prevent.stop="closeMobileMenu"
          >
            {{ $t('Projects') }}
          </Link>
          <Link
            href="/"
            class="header-link"
            @click.prevent.stop="closeMobileMenu"
          >
            {{ $t('Principles') }}
          </Link>
          <Link
            href="/"
            class="header-link"
            @click.prevent.stop="closeMobileMenu"
          >
            {{ $t('Categories') }}
          </Link>
          <Link
            href="/"
            class="header-link"
            @click.prevent.stop="closeMobileMenu"
          >
            {{ $t('FAQ') }}
          </Link>

          <CtaButton
            v-if="width < 1024"
            class="mt-auto"
          />
        </div>
      </div>
      <div class="flex items-center space-x-6">
        <LanguageSwitcher />
        <CtaButton v-if="width > 640" />
        <BurgerButton
          v-if="width < 1024"
          :is-mobile-menu-open="isMobileMenuOpen"
          @click="onMenuToggle()"
        />
      </div>
    </div>
  </header>
</template>

<script setup>
import { useId, useSlots } from 'vue';

const slots = useSlots();
const id = useId();

defineExpose({ id });
</script>

<template>
  <div class="flex flex-col space-y-1">
    <label
      v-if="slots.label"
      :for="id"
      class="block text-[#EBEEF3]"
      :class="{
        'text-[#EBEEF3]': !slots.error,
        'text-red-700': slots.error,
      }"
    >
      <slot name="label" />
    </label>
    <div class="relative">
      <div
        v-if="slots.icon"
        class="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5"
      >
        <slot name="icon" />
      </div>
      <slot />
    </div>
    <div
      v-if="slots.error"
      class="text-sm leading-none text-red-700"
    >
      <slot name="error" />
    </div>
  </div>
</template>

<style scoped></style>

<script setup>
import { defineAsyncComponent } from 'vue';

const blocks = import.meta.glob('./Blocks/*.vue');
const components = {};

Object.entries(blocks).forEach(([path]) => {
  // Get name of component, based on filename
  // "./components/Fruits.vue" will become "Fruits"
  const componentName = path
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');

  components[componentName] = defineAsyncComponent(() => import(`./Blocks/${componentName}.vue`));
});
</script>

<template>
  <template
    v-for="component in $page.props.blocks"
    :key="component.id"
  >
    <component
      :is="components[component.name]"
      :id="component.id"
      :class="component.classes || null"
      v-bind="{ ...component.data, ...(component.settings || {}) }"
    />
  </template>
</template>

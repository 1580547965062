<script setup>
import Checkbox from '@/Components/FormElements/Checkbox.vue';
import Select from '@/Components/FormElements/Select.vue';
import CtaButton from '@/Components/CtaButton.vue';
import TextInput from '@/Components/FormElements/TextInput.vue';
import TextArea from '@/Components/FormElements/TextArea.vue';
import { useForm } from '@inertiajs/vue3';
import { useHoneypot } from '@/Plugins/GlobalStorePlugin.js';
import { ContactSubject } from '@/Enums/Enums.js';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps({
  twoColumns: {
    type: Boolean,
    default: false,
  },
});

const honeypot = useHoneypot(),
  { t, locale } = useI18n();
const form = useForm({
  full_name: null,
  locale: locale.value,
  email: null,
  subject: null,
  message: null,
  terms_accepted: false,
  ...(honeypot != null && honeypot.enabled
    ? {
        [honeypot.nameFieldName]: '',
        [honeypot.validFromFieldName]: honeypot.encryptedValidFrom,
      }
    : {}),
});

const subjects = ref(
  Object.values(ContactSubject).map((value) => ({
    value,
    text: t(value),
  })),
);

const emits = defineEmits(['submitted']);

const onSubmit = () =>
  form.post('/contact/', {
    preserveScroll: true,
    preserveState: true,
    onSuccess: () => emits('submitted'),
  });
</script>

<template>
  <form
    class="grid grid-cols-2 gap-6"
    @submit.prevent="onSubmit"
  >
    <div
      v-if="honeypot?.enabled"
      :name="`${honeypot.nameFieldName}_wrap`"
      style="display: none"
    >
      <input
        :id="honeypot.nameFieldName"
        v-model="form[honeypot.nameFieldName]"
        type="text"
        :name="honeypot.nameFieldName"
      />
      <input
        v-model="form[honeypot.validFromFieldName]"
        type="text"
        :name="honeypot.validFromFieldName"
      />
    </div>
    <TextInput
      v-model="form.full_name"
      :error="form.errors.full_name || null"
      class="col-span-2 sm:col-span-1 md:col-span-2"
      :class="{ 'lg:col-span-1': twoColumns }"
      :label="$t('First name')"
    />
    <TextInput
      v-model="form.email"
      :error="form.errors.email || null"
      class="col-span-2 sm:col-span-1 md:col-span-2"
      :class="{ 'lg:col-span-1': twoColumns }"
      type="email"
      :label="$t('E-Mail')"
    >
      <template #icon>
        <svg
          class="w- h-4"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 3.5C1.71875 3.5 1.5 3.75 1.5 4V4.71875L6.875 9.125C7.53125 9.65625 8.4375 9.65625 9.09375 9.125L14.5 4.71875V4C14.5 3.75 14.25 3.5 14 3.5H2ZM1.5 6.65625V12C1.5 12.2812 1.71875 12.5 2 12.5H14C14.25 12.5 14.5 12.2812 14.5 12V6.65625L10.0625 10.2812C8.84375 11.2812 7.125 11.2812 5.9375 10.2812L1.5 6.65625ZM0 4C0 2.90625 0.875 2 2 2H14C15.0938 2 16 2.90625 16 4V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12V4Z"
            fill="#6A6D72"
          />
        </svg>
      </template>
    </TextInput>
    <Select
      v-model="form.subject"
      :error="form.errors.subject || null"
      class="col-span-2"
      :options="subjects"
      :label="$t('How can we be in help?')"
    />
    <TextArea
      v-model="form.message"
      :error="form.errors.message || null"
      class="col-span-2"
      :label="$t('What project do you have in mind?')"
    />
    <Checkbox
      v-model="form.terms_accepted"
      class="col-span-2"
      :error="form.errors.terms_accepted || null"
    >
      <template #label>
        {{
          $t(
            'By submitting this form, I agree that the information entered will be used in the context of the contract request and the business relationship that may result from it. To know and exercise your rights, including the withdrawal of your consent to the of the data collected by this form, please consult our privacy policy.',
          )
        }}
      </template>
    </Checkbox>
    <div class="col-span-2 text-center">
      <CtaButton type="submit" />
    </div>
  </form>
</template>

<style scoped></style>

import { createI18n } from 'vue-i18n';

const i18nInstance = createI18n({
  legacy: false,
  fallbackLocale: 'en',
  missingWarn: false,
  fallbackWarn: false,
  silentTranslationWarn: true,
});

export const setupI18n = {
  install: (app, options) => {
    app.use(i18nInstance);
    useSetLocale(options.locale);
  },
};
export const useSetLocale = (locale) => {
  if (i18nInstance.mode === 'legacy') {
    i18nInstance.global.locale = locale;
  } else {
    i18nInstance.global.locale.value = locale;
  }
  if (!import.meta.env.SSR) {
    document.querySelector('html').setAttribute('lang', locale);
  }

  import(`./../Locales/${locale}.js`).then((messages) => {
    i18nInstance.global.setLocaleMessage(locale, messages.default);
  });
};

<script setup>
import { useId } from 'vue';

const value = defineModel('modelValue', { default: null, type: [Boolean, Number] });

const id = useId();
</script>

<template>
  <div class="mb-4 flex items-start">
    <input
      :id="id"
      v-model="value"
      type="checkbox"
      class="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
    />
    <label
      :for="id"
      class="ms-2 text-sm text-white"
    >
      <slot name="label" />
    </label>
  </div>
</template>
